import { EntryDayNightBlinds, IEntryDayNightBlinds } from "../../Models/BestaDN";
import { DayNightBlindsType, FabricType } from "../../Models/BestaDN/BasePrice";
import { IEntry } from "../../Models/Entry";
import { EntryComponentProps, EntryRendererProps } from "../EntryComponent";

interface IEntryBestaDNComponentProps extends EntryComponentProps {
  data: IEntryDayNightBlinds
}

const EntryRenderer: React.FC<IEntryBestaDNComponentProps> = ({ data, onDelete, onUpdate }) => {
  return (
    <article>
      <div className='grid'>
        <label>
          Название ткани 
          <select id="fabric-type"
            value={data.fabricType.id}
            onChange={e => onUpdate({ ...data, fabricType: FabricType.ofValue(e.target.value) } as IEntry)}>
            <option value="">Select...</option>
            {FabricType.values.map((element) => (
              <option key={element.id} value={element.id}>
                {element.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          System type
          <select id="system-type"
            value={data.systemType.id}
            onChange={e => onUpdate({ ...data, systemType: DayNightBlindsType.ofValue(e.target.value) } as IEntry)}>
            <option value="">Select...</option>
            {DayNightBlindsType.values.map((element) => (
              <option key={element.id} value={element.id}>
                {element.name}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div className="grid">
        <label>
          Ширина, mm
          <input type="number" value={data.width} onChange={e => onUpdate({ ...data, width: Number.parseInt(e.target.value) } as IEntry)}></input>
        </label>
        <label>
          Высота, mm
          <input type="number" value={data.height} onChange={e => onUpdate({ ...data, height: Number.parseInt(e.target.value) } as IEntry)}></input>
        </label>
      </div>
      <hr />
      <div className='grid'>
        <p className='centered-checkbox'>Цена: <span>{data.cost?.toFixed(2)}</span></p>
        <p className='centered-checkbox'>Всего: <span>{data.totalCost?.toFixed(2)}</span></p>
        <p className='centered-checkbox'>Всего (Ed?): <span>{data.totalFinalCost?.toFixed(2)}</span></p>
        <button onClick={(_e) => onDelete(data)}>Удалить</button>
      </div>
    </article >
  );
}

export const BestaEntriesDN: React.FC<EntryRendererProps> = ({ entries, onDelete, onUpdate }) => {

  return (
    <div>
      {entries.filter(e => e instanceof EntryDayNightBlinds).sort((a, b) => a.idx - b.idx).map(e => <EntryRenderer
        key={e.uuid}
        data={e as IEntryDayNightBlinds}
        onDelete={(e) => onDelete(e)}
        onUpdate={(e) => onUpdate(e)}></EntryRenderer>
      )}
    </div>
  );
}
