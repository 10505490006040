export class DayNightBlindsType {
    static readonly BESTA = new DayNightBlindsType('besta', 'Besta DN', 500);
    static readonly BESTA_DECOR = new DayNightBlindsType('besta_decor', 'Besta DN Décor', 500);
    static readonly BESTA_LUX = new DayNightBlindsType('besta_lux', 'Besta Lux DN', 500);
    static readonly BESTA_LUX_DECOR = new DayNightBlindsType('besta_lux_decor', 'Besta Lux DN Décor', 500);
    static readonly MINI = new DayNightBlindsType('mini', 'Mini DN 19', 500);
    static readonly CLASSIC_25_38 = new DayNightBlindsType('classic_25_38', 'Classic DN 25/38', 1000);
    static readonly CLASSIC_40 = new DayNightBlindsType('classic_40', 'Classic DN 40', 1000);
    static readonly CLASSIC_K = new DayNightBlindsType('classic_k', 'Classic DN K', 1000);

    static readonly values = [
        DayNightBlindsType.BESTA,
        DayNightBlindsType.BESTA_DECOR,
        DayNightBlindsType.BESTA_LUX,
        DayNightBlindsType.BESTA_LUX_DECOR,
        DayNightBlindsType.MINI,
        DayNightBlindsType.CLASSIC_25_38,
        DayNightBlindsType.CLASSIC_40,
        DayNightBlindsType.CLASSIC_K
    ];

    static ofValue(value: string): DayNightBlindsType {
        return DayNightBlindsType.values.find(v => v.id === value) || DayNightBlindsType.BESTA;
    }

    private constructor(
        readonly id: string,
        readonly name: string,
        readonly minimumWidth: number = 0
    ) { }
}

// Price ranges based on height
export class HeightRanges {

    static readonly RANGE_1350 = new HeightRanges(crypto.randomUUID(), 1350, 'До 1350 мм');
    static readonly RANGE_1400 = new HeightRanges(crypto.randomUUID(), 1400, 'До 1400 мм');
    static readonly RANGE_1800 = new HeightRanges(crypto.randomUUID(), 1800, 'До 1800 мм');
    static readonly RANGE_2200 = new HeightRanges(crypto.randomUUID(), 2200, 'До 2200 мм');

    static readonly values = [
        HeightRanges.RANGE_1350,
        HeightRanges.RANGE_1400,
        HeightRanges.RANGE_1800,
        HeightRanges.RANGE_2200
    ];

    public static fromHeight(possibleKeys: string[], height: number): HeightRanges {
        if (possibleKeys.find((e) => e === HeightRanges.RANGE_1350.id) && height <= HeightRanges.RANGE_1350.max) {
            return HeightRanges.RANGE_1350;
        } else if (height <= HeightRanges.RANGE_1400.max) {
            return HeightRanges.RANGE_1400;
        } else if (height <= HeightRanges.RANGE_1800.max) {
            return HeightRanges.RANGE_1800;
        } else if (height <= HeightRanges.RANGE_2200.max) {
            return HeightRanges.RANGE_2200;
        } else {
            throw new Error('Height exceeds maximum limit of 2200mm');
        }
    }

    private constructor(
        readonly id: string,
        readonly max: number,
        readonly name: string
    ) { }
};

export class FabricType {
    static readonly BH_03_10_21 = new FabricType(crypto.randomUUID(), 'BH 03-10-21');
    static readonly BH_1200_1220 = new FabricType(crypto.randomUUID(), 'BH 1200-1220');
    static readonly BH_1301_1307 = new FabricType(crypto.randomUUID(), 'BH 1301-1307');
    static readonly BH_11_18 = new FabricType(crypto.randomUUID(), 'BH 11-18');
    static readonly DN_502 = new FabricType(crypto.randomUUID(), 'DN 502, 505, 801, 804, Vintage, Antique');
    static readonly BH_90 = new FabricType(crypto.randomUUID(), 'ВН 90, 91, 94, 96, Poem, Glamour');

    static readonly values = [
        FabricType.BH_03_10_21,
        FabricType.BH_1200_1220,
        FabricType.BH_1301_1307,
        FabricType.BH_11_18,
        FabricType.DN_502,
        FabricType.BH_90
    ];

    static ofValue(value: string): FabricType {
        return FabricType.values.find(v => v.id === value) || FabricType.BH_03_10_21;
    }

    private constructor(
        readonly id: string,
        readonly name: string
    ) { }
}

// Price tables for all types and height ranges
export const BasePrices = {
    [FabricType.BH_03_10_21.id]: {
        [HeightRanges.RANGE_1350.id]: {
            [DayNightBlindsType.BESTA.id]: 42,
            [DayNightBlindsType.BESTA_DECOR.id]: 54,
            [DayNightBlindsType.BESTA_LUX.id]: 57,
            [DayNightBlindsType.BESTA_LUX_DECOR.id]: 63,
            [DayNightBlindsType.MINI.id]: 34,
            [DayNightBlindsType.CLASSIC_25_38.id]: 47,
            [DayNightBlindsType.CLASSIC_40.id]: 60,
            [DayNightBlindsType.CLASSIC_K.id]: 56
        }
    },
    [FabricType.BH_1200_1220.id]: {
        [HeightRanges.RANGE_1350.id]: {
            [DayNightBlindsType.BESTA.id]: 44,
            [DayNightBlindsType.BESTA_DECOR.id]: 56,
            [DayNightBlindsType.BESTA_LUX.id]: 59,
            [DayNightBlindsType.BESTA_LUX_DECOR.id]: 65,
            [DayNightBlindsType.MINI.id]: 35,
            [DayNightBlindsType.CLASSIC_25_38.id]: 49,
            [DayNightBlindsType.CLASSIC_40.id]: 62,
            [DayNightBlindsType.CLASSIC_K.id]: 58
        }
    },
    [FabricType.BH_1301_1307.id]: {
        [HeightRanges.RANGE_1400.id]: {
            [DayNightBlindsType.BESTA.id]: 44,
            [DayNightBlindsType.BESTA_DECOR.id]: 57,
            [DayNightBlindsType.BESTA_LUX.id]: 59,
            [DayNightBlindsType.BESTA_LUX_DECOR.id]: 66,
            [DayNightBlindsType.MINI.id]: 36,
            [DayNightBlindsType.CLASSIC_25_38.id]: 49,
            [DayNightBlindsType.CLASSIC_40.id]: 64,
            [DayNightBlindsType.CLASSIC_K.id]: 59
        },
        [HeightRanges.RANGE_1800.id]: {
            [DayNightBlindsType.BESTA.id]: 51,
            [DayNightBlindsType.BESTA_DECOR.id]: 66,
            [DayNightBlindsType.BESTA_LUX.id]: 68,
            [DayNightBlindsType.BESTA_LUX_DECOR.id]: 77,
            [DayNightBlindsType.MINI.id]: 43,
            [DayNightBlindsType.CLASSIC_25_38.id]: 55,
            [DayNightBlindsType.CLASSIC_40.id]: 70,
            [DayNightBlindsType.CLASSIC_K.id]: 66
        },
        [HeightRanges.RANGE_2200.id]: {
            [DayNightBlindsType.BESTA.id]: 62,
            [DayNightBlindsType.BESTA_DECOR.id]: 81,
            [DayNightBlindsType.BESTA_LUX.id]: 82,
            [DayNightBlindsType.BESTA_LUX_DECOR.id]: 94,
            [DayNightBlindsType.MINI.id]: null, // Not available
            [DayNightBlindsType.CLASSIC_25_38.id]: 67,
            [DayNightBlindsType.CLASSIC_40.id]: 84,
            [DayNightBlindsType.CLASSIC_K.id]: 79
        }
    },
    [FabricType.BH_11_18.id]: {
        [HeightRanges.RANGE_1400.id]: {
            [DayNightBlindsType.BESTA.id]: 46,
            [DayNightBlindsType.BESTA_DECOR.id]: 57,
            [DayNightBlindsType.BESTA_LUX.id]: 60,
            [DayNightBlindsType.BESTA_LUX_DECOR.id]: 66,
            [DayNightBlindsType.MINI.id]: 37,
            [DayNightBlindsType.CLASSIC_25_38.id]: 51,
            [DayNightBlindsType.CLASSIC_40.id]: 64,
            [DayNightBlindsType.CLASSIC_K.id]: 60
        },
        [HeightRanges.RANGE_1800.id]: {
            [DayNightBlindsType.BESTA.id]: 52,
            [DayNightBlindsType.BESTA_DECOR.id]: 65,
            [DayNightBlindsType.BESTA_LUX.id]: 68,
            [DayNightBlindsType.BESTA_LUX_DECOR.id]: 76,
            [DayNightBlindsType.MINI.id]: 44,
            [DayNightBlindsType.CLASSIC_25_38.id]: 57,
            [DayNightBlindsType.CLASSIC_40.id]: 70,
            [DayNightBlindsType.CLASSIC_K.id]: 67
        },
        [HeightRanges.RANGE_2200.id]: {
            [DayNightBlindsType.BESTA.id]: 63,
            [DayNightBlindsType.BESTA_DECOR.id]: 79,
            [DayNightBlindsType.BESTA_LUX.id]: 82,
            [DayNightBlindsType.BESTA_LUX_DECOR.id]: 91,
            [DayNightBlindsType.MINI.id]: null,
            [DayNightBlindsType.CLASSIC_25_38.id]: 68,
            [DayNightBlindsType.CLASSIC_40.id]: 82,
            [DayNightBlindsType.CLASSIC_K.id]: 77
        }
    },
    [FabricType.DN_502.id]: {
        [HeightRanges.RANGE_1400.id]: {
            [DayNightBlindsType.BESTA.id]: 56,
            [DayNightBlindsType.BESTA_DECOR.id]: 68,
            [DayNightBlindsType.BESTA_LUX.id]: 70,
            [DayNightBlindsType.BESTA_LUX_DECOR.id]: 78,
            [DayNightBlindsType.MINI.id]: 48,
            [DayNightBlindsType.CLASSIC_25_38.id]: 61,
            [DayNightBlindsType.CLASSIC_40.id]: 74,
            [DayNightBlindsType.CLASSIC_K.id]: 70
        },
        [HeightRanges.RANGE_1800.id]: {
            [DayNightBlindsType.BESTA.id]: 62,
            [DayNightBlindsType.BESTA_DECOR.id]: 76,
            [DayNightBlindsType.BESTA_LUX.id]: 79,
            [DayNightBlindsType.BESTA_LUX_DECOR.id]: 87,
            [DayNightBlindsType.MINI.id]: 54,
            [DayNightBlindsType.CLASSIC_25_38.id]: 67,
            [DayNightBlindsType.CLASSIC_40.id]: 80,
            [DayNightBlindsType.CLASSIC_K.id]: 76
        },
        [HeightRanges.RANGE_2200.id]: {
            [DayNightBlindsType.BESTA.id]: 73,
            [DayNightBlindsType.BESTA_DECOR.id]: 89,
            [DayNightBlindsType.BESTA_LUX.id]: 92,
            [DayNightBlindsType.BESTA_LUX_DECOR.id]: 102,
            [DayNightBlindsType.MINI.id]: null,
            [DayNightBlindsType.CLASSIC_25_38.id]: 76,
            [DayNightBlindsType.CLASSIC_40.id]: 90,
            [DayNightBlindsType.CLASSIC_K.id]: 86
        }
    },
    [FabricType.BH_90.id]: {
        [HeightRanges.RANGE_1400.id]: {
            [DayNightBlindsType.BESTA.id]: 54,
            [DayNightBlindsType.BESTA_DECOR.id]: 63,
            [DayNightBlindsType.BESTA_LUX.id]: 68,
            [DayNightBlindsType.BESTA_LUX_DECOR.id]: 73,
            [DayNightBlindsType.MINI.id]: 46,
            [DayNightBlindsType.CLASSIC_25_38.id]: 58,
            [DayNightBlindsType.CLASSIC_40.id]: 69,
            [DayNightBlindsType.CLASSIC_K.id]: 66
        },
        [HeightRanges.RANGE_1800.id]: {
            [DayNightBlindsType.BESTA.id]: 61,
            [DayNightBlindsType.BESTA_DECOR.id]: 72,
            [DayNightBlindsType.BESTA_LUX.id]: 76,
            [DayNightBlindsType.BESTA_LUX_DECOR.id]: 82,
            [DayNightBlindsType.MINI.id]: 52,
            [DayNightBlindsType.CLASSIC_25_38.id]: 64,
            [DayNightBlindsType.CLASSIC_40.id]: 75,
            [DayNightBlindsType.CLASSIC_K.id]: 72
        },
        [HeightRanges.RANGE_2200.id]: {
            [DayNightBlindsType.BESTA.id]: 72,
            [DayNightBlindsType.BESTA_DECOR.id]: 85,
            [DayNightBlindsType.BESTA_LUX.id]: 90,
            [DayNightBlindsType.BESTA_LUX_DECOR.id]: 97,
            [DayNightBlindsType.MINI.id]: null,
            [DayNightBlindsType.CLASSIC_25_38.id]: 76,
            [DayNightBlindsType.CLASSIC_40.id]: 87,
            [DayNightBlindsType.CLASSIC_K.id]: 84
        }
    }
};

// Additional components prices
export const ComponentPrices = {
    SIDE_GUIDES_33MM: {
        [DayNightBlindsType.MINI.id]: 5,
        [DayNightBlindsType.CLASSIC_25_38.id]: 7
    },
    SIDE_GUIDES_36MM: {
        [DayNightBlindsType.MINI.id]: 6
    },
    U_GUIDES: {
        [DayNightBlindsType.MINI.id]: 9,
        [DayNightBlindsType.CLASSIC_25_38.id]: 11
    },
    SEPARATE_GUIDE_KITS: {
        GUIDE_32MM: 5,
        GUIDE_36MM: 6,
        U_GUIDE: 6
    },
    CHAIN_WEIGHT: {
        CASSETTE: 1,
        ROLLER: 1
    },
    CHILD_SAFETY: 1,
    METAL_CHAIN: 6
};


// Colors
export const Colors = {
    WHITE: { id: 'white', name: 'Белый' },
    BROWN: { id: '02', name: 'Коричневый' },
    ANODIZED: { id: '04', name: 'Анод' },
    MAHOGANY: { id: '22', name: 'Махагон' },
    DARK_GREY: { id: '23', name: 'RAL7043(темно-серый)' },
    WALNUT: { id: '30', name: 'Орех' },
    DARK_OAK: { id: '34', name: 'Темный дуб' },
    LIGHT_WOOD: { id: '47', name: 'Светлое дерево' },
    GOLDEN_OAK: { id: '48', name: 'Золотой дуб' }
};

// Additional options
export const AdditionalOptions = {
    CHAIN_CONTROL: { name: 'Управление цепочкой', included: true },
    MOTOR_CONTROL: { name: 'Управление мотором', price: 0 }, // Price from automation price list
    U_GUIDES: { name: 'Комплект U-направляющих', price: 6 },
    CHAIN_WEIGHT: { name: 'Утяжелитель цепочки', price: 1 },
    CHILD_SAFETY: { name: 'Безопасность ребенка', price: 1 }
};
