import { ED_COST_MULTIPLIER } from "../constants";
import { Entry, IEntry } from "../Entry";
import { BasePrices, ComponentPrices, DayNightBlindsType, FabricType, HeightRanges } from "./BasePrice";

export interface IEntryDayNightBlinds extends IEntry {
    width: number;
    height: number;
    fabricType: FabricType;
    systemType: DayNightBlindsType;
    options: {
        sideGuides?: boolean;
        uGuides?: boolean;
        metalChain?: boolean;
        childSafety?: boolean;
        chainWeight?: boolean;
    };
}

export class EntryDayNightBlinds extends Entry {
    constructor(readonly uuid: string,
        readonly idx: number,
        readonly width: number,
        readonly height: number,
        readonly fabricType: FabricType,
        readonly systemType: DayNightBlindsType,
        readonly options: {
            sideGuides?: boolean;
            uGuides?: boolean;
            metalChain?: boolean;
            childSafety?: boolean;
            chainWeight?: boolean;
        } = {}
    ) {
        super(uuid, idx);
    }

    get totalCost(): number {
        return this.cost;
    }

    get totalFinalCost(): number {
        return this.totalCost * ED_COST_MULTIPLIER;
    }

    get cost(): number {
        let basePrice = this.getBasePrice();
        let addonsPrice = this.calculateAddons();

        // Apply minimum width rules
        basePrice = this.applyMinimumWidthRules(basePrice);

        return basePrice + addonsPrice;
    }

    private calculateAddons(): number {
        let addonsTotal = 0;

        // if (this.options.sideGuides) {
        //     if (this.systemType === DayNightBlindsType.MINI) {
        //         addonsTotal += ComponentPrices.SIDE_GUIDES_33MM.mini;
        //     } else if (this.systemType === DayNightBlindsType.CLASSIC_25_38) {
        //         addonsTotal += ComponentPrices.SIDE_GUIDES_33MM.classic_25_38;
        //     }
        // }
        //
        // if (this.options.uGuides) {
        //     if (this.systemType === DayNightBlindsType.MINI) {
        //         addonsTotal += ComponentPrices.U_GUIDES.mini;
        //     } else if (this.systemType === DayNightBlindsType.CLASSIC_25_38) {
        //         addonsTotal += ComponentPrices.U_GUIDES.classic_25_38;
        //     }
        // }
        //
        // if (this.options.metalChain) {
        //     addonsTotal += ComponentPrices.METAL_CHAIN;
        // }
        //
        // if (this.options.childSafety) {
        //     addonsTotal += ComponentPrices.CHILD_SAFETY;
        // }
        //
        // if (this.options.chainWeight) {
        //     addonsTotal += ComponentPrices.CHAIN_WEIGHT.CASSETTE;
        // }

        return addonsTotal;
    }

    private applyMinimumWidthRules(price: number): number {
        // Apply minimum width payment rules
        const minimumWidth = this.getMinimumWidth();
        const actualWidth = Math.max(this.width, minimumWidth);

        // Adjust price based on actual width vs provided width
        return price * (actualWidth / this.width);
    }

    private getMinimumWidth(): number {
        return this.systemType.minimumWidth
    }

    private getBasePrice(): number {
        // Get correct price table based on fabric type
        const priceTable = BasePrices[this.fabricType.id];

        // Get price range based on height
        const heightRange = HeightRanges.fromHeight(Object.keys(priceTable), this.height);

        // Return base price for system type and height range
        return priceTable[heightRange.id][this.systemType.id] || 0;
    }

    static create(idx: number): Entry {
        return new EntryDayNightBlinds(
            crypto.randomUUID(),
            idx,
            500,
            500,
            FabricType.BH_03_10_21,
            DayNightBlindsType.BESTA
        );
    }

    static fromData(data: IEntryDayNightBlinds): Entry {
        return new EntryDayNightBlinds(
            data.uuid,
            data.idx,
            data.width,
            data.height,
            data.fabricType,
            data.systemType,
            data.options
        );
    }
}

