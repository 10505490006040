import '@picocss/pico';
import { useState } from 'react';
import './App.css';
import { Entry, IEntry } from './Models/Entry';
import { EntryRendererProps } from './Components/EntryComponent';
import { PlisseEntries } from './Components/Plisse/PlisseEntries';
import { BestaEntries, EntryBesta, IEntryBesta } from './Components/Besta/BestaEntries';
import { BestaEntriesDN } from './Components/BestaDN/BestaDNEntries';
import { MosquitoNetEntires } from './Components/MosquitoNet/MosquitoNetEntries';
import { EntryPlisse, IEntryPlisse } from './Models/Plisse';
import { EntryMosquitoNet, IEntryMosquitoNet } from './Models/MosquitoNet';
import { EntryDayNightBlinds, IEntryDayNightBlinds } from './Models/BestaDN';


class EntryType {

    private constructor(
        readonly id: string,
        readonly name: string,
        readonly component: React.FC<EntryRendererProps>,
        readonly newEntry: (idx: number) => Entry,
        readonly updateEntry: (e: IEntry) => Entry) {
    }

    isSelected(currentlySelected: EntryType): boolean {
        return this.id === currentlySelected.id
    }

    static values = [
        new EntryType(crypto.randomUUID(), "Plisse", PlisseEntries, EntryPlisse.create, (e) => EntryPlisse.fromData(e as IEntryPlisse)),
        new EntryType(crypto.randomUUID(), "Besta", BestaEntries, EntryBesta.create, (e) => EntryBesta.fromData(e as IEntryBesta)),
        new EntryType(crypto.randomUUID(), "Day/Night blinds", BestaEntriesDN, EntryDayNightBlinds.create, (e) => EntryDayNightBlinds.fromData(e as IEntryDayNightBlinds)),
        new EntryType(crypto.randomUUID(), "Mosquito net", MosquitoNetEntires, EntryMosquitoNet.create, (e) => EntryMosquitoNet.fromData(e as IEntryMosquitoNet)),
    ]
}


function App() {
    const [entries, setEntries] = useState([EntryPlisse.create(0), EntryPlisse.create(1)])
    const [entryTypes, _] = useState(EntryType.values)
    const [currentlySelected, setCurrentlySelected] = useState(entryTypes[0])

    const updateEntry = (e: IEntry) => {
        const entr = [...entries.filter(en => en.uuid !== e.uuid), currentlySelected.updateEntry(e)]
        setEntries(entr)
    }
    const deleteEntry = (e: IEntry) => {
        const entr = [...entries.filter(en => en.uuid !== e.uuid)]
        setEntries(entr)
    }

    const addNewEntry = () => {
        setEntries([...entries, currentlySelected.newEntry(entries.length)])
    }

    const selectEntryType = (e: EntryType) => {
        setCurrentlySelected(e)
    }

    return (
        <div className="container">
            <header>
                <div className='grid'>
                    {entryTypes.map(e =>
                        <button
                            key={e.id}
                            onClick={(_) => selectEntryType(e)}
                            disabled={e.isSelected(currentlySelected)}>{e.name}</button>
                    )}
                </div>
            </header>
            <main>
                {currentlySelected.component({
                    entries: entries,
                    onDelete: (e) => deleteEntry(e),
                    onUpdate: (e) => updateEntry(e)
                })}

                <div className='grid'>
                    <button onClick={(_e) => addNewEntry()}>Добавить</button>
                </div>
            </main>
            <footer>
                <div className='grid'>
                    <p className='centered-checkbox'>Всего: <span>{entries.map(e => e.totalCost).reduce((a, c) => a + c, 0).toFixed(2)}</span></p>
                    <p className='centered-checkbox'>Всего (Ed?): <span>{entries.map(e => e.totalFinalCost).reduce((a, c) => a + c, 0).toFixed(2)}</span></p>
                </div>
            </footer>
        </div>
    );
}

export default App;
